import React from 'react'

const EmailAtIcon = () => {
  return (
    <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M45 22.5C45 34.9256 34.9256 45 22.5 45C10.0744 45 0 34.9256 0 22.5C0 10.0744 10.0744 0 22.5 0C34.9256 0 45 10.0744 45 22.5Z" fill="#BD4FF4" />
      <path
        d="M25.852 27.0448H25.7602C24.8803 29.673 23.1559 30.9877 20.5892 30.9877C18.9503 30.9877 17.6305 30.3736 16.6275 29.1444C15.6246 27.9153 15.1242 26.2618 15.1242 24.183C15.1242 21.4327 15.8123 19.1464 17.1874 17.3241C18.5625 15.5017 20.3755 14.5916 22.6253 14.5916C23.4802 14.5916 24.2454 14.8272 24.9178 15.2974C25.5903 15.7686 26.024 16.3336 26.22 16.9936H26.2752C26.3065 16.7027 26.3774 16.1512 26.4879 15.338C26.5234 15.0795 26.7423 14.8845 27.0029 14.8845H28.8399C29.3351 14.8845 29.5707 15.1566 29.5436 15.461C29.0025 21.536 28.7325 24.6323 28.7325 24.7512C28.7325 27.172 29.3622 28.3823 30.6216 28.3823C31.7705 28.3823 32.7244 27.7078 33.4824 26.3567C34.2403 25.0055 34.6198 23.2541 34.6198 21.1023C34.6198 17.9235 33.596 15.3286 31.5474 13.3176C29.4988 11.3065 26.6599 10.3004 23.0288 10.3004C19.5446 10.3004 16.6588 11.5171 14.3725 13.9493C12.0862 16.3816 10.9425 19.4269 10.9425 23.082C10.9425 26.6882 12.0424 29.599 14.2443 31.8113C16.4451 34.0246 19.4101 35.1307 23.1382 35.1307C25.7415 35.1307 28.0121 34.7356 29.9523 33.9475C30.2995 33.8057 30.6758 34.0455 30.6758 34.4208V36.093C30.6758 36.3088 30.5486 36.5059 30.3474 36.5851C28.2644 37.4004 25.7029 37.809 22.6618 37.809C18.1622 37.809 14.5372 36.4642 11.7859 33.7744C9.03466 31.0846 7.65955 27.5639 7.65955 23.2113C7.65955 18.7367 9.11494 15.0253 12.0247 12.079C14.9344 9.13279 18.6511 7.65967 23.1747 7.65967C27.4168 7.65967 30.9292 8.89508 33.7107 11.3638C36.4922 13.8336 37.8829 17.0551 37.8829 21.0293C37.8829 23.939 37.1281 26.3264 35.6185 28.1905C34.1089 30.0556 32.2657 30.9877 30.0889 30.9877C27.2771 30.9877 25.8645 29.6741 25.852 27.0448ZM22.936 17.1958C21.5911 17.1958 20.5058 17.8558 19.6801 19.1767C18.8544 20.4976 18.4426 22.1417 18.4426 24.11C18.4426 25.4309 18.721 26.4734 19.2766 27.2376C19.8323 28.0018 20.5756 28.3834 21.5046 28.3834C22.8494 28.3834 23.9128 27.6901 24.6958 26.3014C25.4777 24.9138 25.8686 23.0768 25.8686 20.7905C25.8707 18.3948 24.8918 17.1958 22.936 17.1958Z"
        fill="white"
      />
    </svg>
  )
}

export default EmailAtIcon
